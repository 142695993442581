<script setup>
    import { ref, onMounted } from 'vue'
    import { useRouter } from 'vue-router'
    import { useSignupStore } from "../stores/signup";
    import insightsNotifyService from "../services/insights/insightsNotifyService";
    import {useUserStore} from "../stores/user";
    import {useHomeStore} from "../../../stores/homeStore"
    import 'vue3-carousel/dist/carousel.css'
    import { Carousel, Slide, Pagination, Navigation } from 'vue3-carousel'
    import mainBannerService from "../services/banner/mainBannerService";
    import corpBannerService from "../services/banner/corpBannerService";
    import _ from "lodash";
    import {useSiteMenuStore} from "../../../stores/sitemenu";
    import lectureUserService from "../services/lecture/lectureUserService";

    const router = useRouter()
    const userStore = useUserStore()
    const signupStore = useSignupStore()
    const siteMenuStore = useSiteMenuStore();
    const homeStore = useHomeStore();

    onMounted(()=>{
        getMainBanners(); //메인배너 가져오기
        getCorpBanners(); //메인배너 가져오기
        getNoticeList(); // 공지사항 가져오기
        getLectureList(); // 대표강의 가져오기
        signupStore.setSignupRequest({});
        siteMenuStore.getSiteMenu();
    })

    const SLIDE_AUTO_PLAY = 3000;
    const SLIDE_TRANSITION = 1000;

    const getMainBanners = () => {
        let paramObj = {
            pageNo: 1,
            size:  100,
            searchType: '',
            searchInput: '',
        }

        mainBannerService.retrieveAllBanner(paramObj)
            .then( async (res) => {
                // console.log(res);
                if(res.data.code.toLowerCase() === 'success') {
                    const result = res.data.result;
                    const mainBanners = _.orderBy(result, 'order');
                    homeStore.setMainBanners(mainBanners);
                } else {
                }
            }).catch( (error) =>  {
        });
    }

    const getCorpBanners  = () => {
        let paramObj = {
            pageNo: 1,
            size:  100,
            searchType: '',
            searchInput: '',
        }

        corpBannerService.retrieveAllBanner(paramObj)
            .then( async (res) => {
                // console.log(res);
                if(res.data.code.toLowerCase() === 'success') {
                    const result = res.data.result;
                    const corpBanners = _.orderBy(result, 'order');
                    homeStore.setCorpBanners(corpBanners);
                } else {
                }
            }).catch( (error) =>  {
            // console.log(error);
        });
    }

    const getNoticeList = () => {
        let paramObj = {
            pageNo: 1,
            size: 5,
        }

        insightsNotifyService.retrieveAllPaging(paramObj)
            .then( (res) => {
                if(res.data.code.toLowerCase() === 'success') {
                    const result = res.data.result;
                    homeStore.setNoticeList(result.content);
                }
            }).catch( (error) =>  {
            console.log(error);
        });
    }

    const getLectureList = () => {
        let paramObj = {
            repreLectYn: "Y",
            // lectGb: "01",
            lectOpenYn: "Y",
            pageNo: 1,
            size: 4,
        }

        lectureUserService.retrieveAllPaging(paramObj)
            .then( (res) => {
                if(res.data.code.toLowerCase() === 'success') {
                    const result = res.data.result;
                    homeStore.setLectureList(result.content);
                }
            }).catch( (error) =>  {
            // console.log(error);
        });
    }

    const goLectureDetail = (lect) => {
        router.push({name: lect.lectGb === '01' ? 'LectureOnlineDetail' : 'LectureOfflineDetail', params: {id: lect.lectId}})
    }
</script>

<template>
    <div class="container">
        <div class="slide-wrap">
            <ul>
                <carousel
                    :autoplay="SLIDE_AUTO_PLAY"
                    :wrap-around="true"
                    :transition="SLIDE_TRANSITION"
                    :items-to-show="1">
                    <slide v-for="banner in homeStore.mainBanners" :key="banner.bannerId">
                      <a v-if="banner.url.length > 0" :href="banner.url"><img :src="banner.fileDir" alt=""></a>
                      <img v-else :src="banner.fileDir" alt="">
                    </slide>

                    <template #addons>
                        <div class="inner">
                            <navigation />
                        </div>
                        <pagination />
                    </template>
                </carousel>
            </ul>
        </div>
        <div class="inner">
            <div class="section-wrap">
                <div class="lecture-main-wrap" v-if="homeStore.lectureList.length > 0">
                    <div class="img-wrap" v-for="lecture in homeStore.lectureList" @click="goLectureDetail(lecture)">
                        <div class="img-bg" :style="{'background-image': `url(${lecture.lectThombDir})`}"></div>
                        <img :src="lecture.lectThombDir" />
                    </div>
                </div>
                <div>
                    <section class="notice-wrap">
                        <h3>
                            공지사항
                            <KSATextButton class="black_button btn-more" text="더보기" @click="router.push({name:'InsightsNotify'})" />
                        </h3>
                        <ul>
                            <li v-for="(notice, index) in homeStore.noticeList" :key="notice.boardId">
                                <p v-if="notice.notifyYn === 'Y'" @click="router.push({name:'InsightsNotifyDetail', params:{id:notice.boardId}})" style="cursor:pointer; font-weight: bold; color: blue;">📣{{ notice.title }}<img v-if="notice.attachList.length > 0 && notice.attachList[0].fileName" src="/src/assets/images/icon-file.png" class="icon-file" /></p>
                                <p v-else @click="router.push({name:'InsightsNotifyDetail', params:{id:notice.boardId}})" style="cursor:pointer">{{ notice.title }}<img v-if="notice.attachList.length > 0 && notice.attachList[0].fileName" src="/src/assets/images/icon-file.png" class="icon-file" /></p>
                                <span>{{ $yyyymmddOrHHmm(notice.createDate) }}</span>
                            </li>
                            <li class="result-none" v-if="homeStore.noticeList.length === 0">
                                공지사항이 등록되어있지 않습니다.
                            </li>
                        </ul>
                    </section>
                    <section class="slide-wrap slide2 mt-xl">
                        <carousel
                            :autoplay="SLIDE_AUTO_PLAY"
                            :wrap-around="true"
                            :transition="SLIDE_TRANSITION"
                            :items-to-show="1">
                            <slide v-for="banner in homeStore.corpBanners" :key="banner.bannerId">
                              <a v-if="banner.url.length > 0" :href="banner.url">
                                <img :src="banner.fileDir" />
                              </a>
                              <img v-else :src="banner.fileDir" />
                            </slide>

                            <template #addons>
                                <div class="inner">
                                    <navigation />
                                </div>
                                <pagination />
                            </template>
                        </carousel>
                    </section>
                </div>
            </div>
            <div class="company-wrap">
                <div class="logo1"><a href="http://www.motie.go.kr/www/main.do" target="_blank"><img src="/src/assets/images/home-logo_motie.png" alt="산업통상자원부" /></a></div>
                <div class="logo2"><a href="https://www.kiat.or.kr/front/user/main.do" target="_blank"><img src="/src/assets/images/home-logo_kiat.png" alt="KIAT 한국산업기술진흥원" /></a></div>
                <div class="logo3"><a href="https://www.ksia.or.kr/" target="_blank"><img src="/src/assets/images/home-logo_ksia.png" alt="KSIA 한국반도체산업협회" /></a></div>
                <div class="logo4"><a href="https://academy.ksia.or.kr/"><img src="/src/assets/images/logo.svg" alt="KSA 한국반도체아카데미" /></a></div>
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
    @import "../../../assets/scss/theme";

    .slide-wrap {
        height: 714px;
        overflow: hidden;
        ul {
            li {
                height: 714px;
                position: relative;
                &:before {
                    content: "";
                    position: absolute;
                    top: -34%;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    background: linear-gradient(180deg, rgba(0, 0, 0, 0.2) 10%, transparent);
                }
                a {
                  display: block;
                  width: 100%;
                  height: 100%;
                }
                img {
                    object-fit: cover;
                    width: 100%;
                    height: 100%;
                    object-position: center center;
                }
                div {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    z-index: 1;
                    transform: translate3d(-50%, -50%, 0);
                    color: $blue-light_color;
                    @include font-size_xxxl;
                    font-weight: 500;
                    text-align: center;
                    p {
                        @include font-size_md;
                        font-weight: 400;
                        margin-top: 30px;
                    }
                }
            }
        }
        &.slide2 {
            width: 564px !important;
            height: 292px;
            ol {
                li {
                    height: 292px;
                    img {
                        object-fit: cover;
                        width: 100%;
                        height: 100%;
                        object-position: center center;
                    }
                }
            }
            .carousel, .carousel__viewport {
                width: 100%;
                height: 100%;
            }
            .carousel__pagination {
                bottom: 20px;
            }
        }
    }
    .section-wrap {
        margin: 60px 0;
        > div {
            display: flex;
        }
        .lecture-main-wrap {
            border-top: 0;
            padding-top: 0;
            margin-bottom: 100px;
            .img-wrap {
                position: relative;
                height: 246px;
                overflow: hidden;
                margin-right: 20px;
                cursor:pointer;
                width:100%;
                &:last-child {
                    margin-right: 0;
                }
                .img-bg {
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    top: 0;
                    left: 0;
                    background-repeat: no-repeat;
                    background-size: cover;
                    background-position: center;
                    filter: blur(50px);
                    overflow: hidden;
                    &:before {
                        content: "";
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        background-color: rgba(0,0,0,0.3);
                    }
                }
                img {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    width: 346px;
                    height: 100%;
                    object-fit: contain;
                    object-position: center;
                    z-index: 1;
                    transform: translate3d(-50%, -50%, 0);
                }
            }
        }
        section {
            width: fit-content;
            &.notice-wrap {
                width: 836px;
                margin-right: 40px;
            }
            h3 {
                display: flex;
                justify-content: space-between;
                border-bottom: 2px solid $black_color;
                @include font-size_lg;
                padding-bottom: 20px;
                .btn-more {
                    @include font-size_xs;
                }
            }
            ul {
                display: block;
                li {
                    display: flex;
                    justify-content: space-between;
                    margin-top: 30px;
                    &.result-none {
                        display: block;
                        height: 292px;
                        text-align: center;
                        margin-top: 0;
                    }
                    p {
                        width: calc(100% - 120px);
                        display: inline-block;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        overflow: hidden;
                    }
                    span {
                        color: $gray_color;
                    }
                }
            }
        }
    }
    .company-wrap {
        padding-top: 60px;
        border-top: $border_line;
        div {
            width: 345px;
            height: 100px;
            border: $border_line;
            &.logo1 {
                img {
                    padding: 6px 0;
                }
            }
            &.logo2 {
                position: relative;
                img {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    display: block;
                    width: fit-content;
                    height: fit-content;
                    border: 0;
                    margin: auto;
                    transform: translate3d(-50%, -50%, 0);
                }
            }
            &.logo3 {
                img {
                    padding: 8px 0;
                }
            }
            &.logo4 {
                img {
                    padding: 22px 0;
                }
            }
            img {
                width: 100%;
                height: 100%;
                border: 0;
                object-fit: contain;
            }
        }
    }
</style>
