<template>
    <Modal ref="baseModal">
        <div class="modal-inner xl-size">
            <button class="close" @click="cancel"></button>
            <div class="modal-title">
                <h3>기업 홍보 배너 {{ input.bannerId ? '수정' : '추가'}}</h3>
            </div>
            <div class="modal-body">
                <p class="text-left">모든 정보는 필수 입력사항 입니다.</p>
                <div class="table-form">
                    <table class="vertical">
                        <colgroup>
                            <col width="150px" />
                            <col />
                        </colgroup>
                        <tr>
                            <th>배너</th>
                            <td class="text-box">
                                <div class="grid file">
                                    <KSAPrimaryButton text="파일 선택" @click="uploadFile" />
                                    <KSAInput class="input icon" placeholder="이미지 선택(564pxX292px 사이즈 이미지를 권장합니다.)" v-model="input.fileName" disabled />
                                    <button class="delete" @click="removeFile" :disabled="!input.fileName"><i></i></button>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <th>노출 순서</th>
                            <td><KSAInput v-model="input.order" placeholder="1이상의 숫자를 입력하세요." max-length="2" /></td>
                        </tr>
                        <tr>
                            <th>URL 경로</th>
                            <td><KSAInput v-model="input.url" placeholder="URL 경로를 입력하세요." /></td>
                        </tr>
                    </table>
                </div>
            </div>
            <div class="modal-footer mt-xs" style="justify-content: center;">
                <KSASecondaryButton class="gray_button" text="취소" @click="cancel" />
                <KSAPrimaryButton class="ml-xs" :text="input.bannerId ? '저장' : '추가'" @click="confirm" :disabled="!input.fileName || input.order === ''"/>
            </div>
        </div>
    </Modal>
</template>


<script>
import Modal from "../../../../../components/ui-components/modal/Modal.vue";
import { ref, onMounted } from "vue";
import {usePageLoadingStore} from "../../../../../stores/pageLoading";
import corpBannerService from "../../../../user/services/banner/corpBannerService";
import fileService from "../../../../../services/fileService";

export default {
    name: "LectureMailModal",
    components: {
        Modal,
    },
    props: {
    },
    setup() {
        const baseModal = ref(null);
        const resolvePromise = ref(null);

        const loadingStore = usePageLoadingStore()

        onMounted(()=>{
            setTimeout(()=>{
                baseModal.value.isAdminWrap = true;
            }, 500);
        })

        const input = ref({
            bannerGb: '02', // 01 메인 02 기업홍보
            bannerId: '',
            fileName: '',
            fileSize: '',
            fileDir: '',
            order: '',
            url: '',
        })

        const uploadFile = () => {
            const fileInput = document.createElement('input');
            fileInput.type= 'file';
            fileInput.click();

            fileInput.addEventListener('change', function(event){
                // console.log(event.target.files);
                const file = event.target.files[0];

                let paramObj = {
                    container: 'public',
                    folder: 'corp_banner',
                    file,
                }

                fileService.uploadFile(paramObj)
                    .then((res) => {
                        // console.log(res);
                        if(res.data.code.toLowerCase() === 'success') {
                            const result = res.data.result;
                            // console.log(result);
                            input.value.fileName = result.originalFilename;
                            input.value.fileSize = result.fileSize;
                            input.value.fileDir = result.blobUrl;
                        }
                    })
                    .catch( (error) =>  {
                        console.log(error);
                    });
            });
        }

        const removeFile = () => {
            input.value.fileName = '';
            input.value.fileSize =  '';
            input.value.fileDir =  '';
        }

        const show = () => {
            baseModal.value.open();
            return new Promise((resolve, _) => {
                resolvePromise.value = resolve;
            });
        };

        const confirm = () => {
            let paramObj = {
                bannerGb: input.value.bannerId || '02',
                bannerId: input.value.bannerId,
                bannerName: input.value.fileName,
                fileName: input.value.fileName,
                fileSize: input.value.fileSize,
                fileDir: input.value.fileDir,
                order: input.value.order,
                url: input.value.url,
            }
            loadingStore.loading = true;

            if(paramObj.bannerId) { // 수정
                corpBannerService.updateBanner(paramObj)
                    .then((res) => {
                        // console.log(res);
                        loadingStore.loading = false;
                        if(res.data.code.toLowerCase() === 'success') {
                            const result = res.data.result;
                            // console.log(result);
                            success();
                        }
                    })
                    .catch( (res) =>  {
                        loadingStore.loading = false;
                        console.log(res);
                    });

            } else { // 생성
                corpBannerService.createBanner(paramObj)
                    .then((res) => {
                        // console.log(res);
                        loadingStore.loading = false;
                        if(res.data.code.toLowerCase() === 'success') {
                            const result = res.data.result;
                            // console.log(result);
                            success();
                        }
                    })
                    .catch( (res) =>  {
                        loadingStore.loading = false;
                        console.log(res);
                    });
            }
        };

        function success() {
            init();
            baseModal.value.close();
            resolvePromise.value(true);
        }

        const cancel = () => {
            init();
            baseModal.value.close();
            resolvePromise.value(false);
        };

        const init = (paramObj) => {
            if(paramObj) {
                input.value = paramObj;
            } else {
                input.value = {
                    bannerGb: '02',
                    bannerId: '',
                    fileName: '',
                    fileSize: '',
                    fileDir: '',
                    order: '',
                    url: '',
                }
            }
        }

        return { baseModal, show, confirm, cancel, input, uploadFile, removeFile, init };
    },
};
</script>

<style lang="scss" scoped>
    @import "../../../../../assets/scss/theme";

    .modal-inner {
        /*
        .text-box {
            .file {
                .input {
                    width: calc(100% - 130px);
                }
                .delete {
                    background: transparent;
                    border: 0;
                    padding: 0;
                    cursor: pointer;
                    &:disabled {
                        cursor: default;
                        i {
                            background-position: -135px -51px;
                        }
                    }
                    i {
                        display: block;
                        width: 14px;
                        height: 16px;
                        background-image: url(/src/assets/images/icon-main.png);
                        background-size: 200px 200px;
                        background-position: -28px -51px;
                        margin-left: 10px;
                    }
                }
            }
        }
         */
    }
</style>
