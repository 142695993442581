import http from '../../../../api/http';

function createBanner(paramObj) {
    return http.post('/banner/main/create', {
        bannerId: paramObj.bannerId,
        bannerGb: paramObj.bannerGb || '01',
        bannerName: paramObj.bannerName,
        fileName: paramObj.fileName,
        fileSize: paramObj.fileSize,
        fileDir: paramObj.fileDir,
        order: paramObj.order,
        createId: paramObj.createId,
        url: paramObj.url,
    })
}

function updateBanner(paramObj) {
    return http.post('/banner/main/update', {
        bannerId: paramObj.bannerId,
        bannerGb: paramObj.bannerGb || '01',
        bannerName: paramObj.bannerName,
        fileName: paramObj.fileName,
        fileSize: paramObj.fileSize,
        fileDir: paramObj.fileDir,
        order: paramObj.order,
        createId: paramObj.createId,
        url: paramObj.url,
    })
}

function deleteBanner(bannerId) {
    return http.post('/banner/main/delete', {
        bannerId,
    })
}

function retrieveBannerDetail(paramObj) {
    return http.post('/banner/main/retrieve', {
        bannerId: paramObj.bannerId,
    })
}

function retrieveAllBanner(paramObj) {
    return http.post('/banner/main/retrieveAll', {
        bannerGb: paramObj.bannerGb || '01',
        pageNumber: paramObj.pageNo || 1,
        size: paramObj.size || 30,
        searchType: paramObj.searchType,
        searchInput: paramObj.searchInput,
    })
}

export default {
    createBanner,
    updateBanner,
    deleteBanner,
    retrieveBannerDetail,
    retrieveAllBanner,
};
